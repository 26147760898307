<template>
	<v-flex pb-3 shrink>
		<v-layout v-if="loading" align-center class="w-loading-container" justify-center style="max-height: 51px; min-height: 51px">
			<v-progress-circular color="primary" indeterminate />
		</v-layout>
		<v-alert color="warning" icon="warning" :value="!loading && value.length == 0">{{ $t('customers.warning.no_app') }}</v-alert>
		<v-expansion-panel :value="panel" @input="setPanel">
			<ApplicationVendorPanel
				v-for="app in value"
				:key="app.id"
				:title="app.title"
				:fields="app.formattedParams"
				:value="app"
				@select="select(app, $event)"
				@unselect="unselect(app)"
			/>
		</v-expansion-panel>
	</v-flex>
</template>

<script>
export default {
	name: 'VendorApplicationsExpansionPanel',
	components: {
		ApplicationVendorPanel: () => ({
			component: import('@/components/Applications/ApplicationVendorPanel')
        })
	},
	props: {
		loading: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			default: () => [],
			required: false,
			type: Array
		}
	},
	data: function () {
		return {
			panel: null
		}
	},
	computed: {
		isAdmin: function () {
			return this.selectedAccountingFirm?.isAccountantAdmin ?? false
		}
	},
	methods: {
		select: function (row, params = {}) {
			this.$emit('select', row, params)
		},
		setPanel: function (panel) {
			this.panel = panel
		},
		unselect: function (row) {
			this.$emit('unselect', row)
		}
	}
}
</script>